<template>
  <div class="overlayContainer">
    <div id="pollContainer" ref="pollContainer"></div>
    <div
      id="slideContainer"
      ref="slideContainer"
      v-bind:style="slideVisible ? 'dispay:flex;' : 'display:none;'"
    ></div>
  </div>
</template>
<script>
var overlayContent = require("../assets/overlayContent.json");

import "../scss/overlayBase.css";
import Vue from "vue";

export default {
  components: {},
  mounted() {
    overlayContent.forEach(async (element) => {
      if (element.type == "quizQuestion") {
        const ComponentClass = Vue.extend(
          (await import("./quiz-question")).default
        );
        element.instance = new ComponentClass({
          propsData: {
            InstanceData: element.instanceData,
          },
        });
        element.instance.$on("setChildInactive", this.setChildInactiveEventFromChild);
        element.instance.$mount();
        element.isActive = false;
        this.$refs.pollContainer.appendChild(element.instance.$el);
      }
      if (element.type == "quizAnswer") {
        const ComponentClass = Vue.extend(
          (await import("./quiz-answer")).default
        );
        element.instance = new ComponentClass({
          propsData: {
            InstanceData: element.instanceData,
          },
        });
        element.instance.$on("setChildInactive", this.setChildInactiveEventFromChild);
        element.instance.$mount();
        element.isActive = false;
        this.$refs.pollContainer.appendChild(element.instance.$el);
      } else if (element.type == "slidePlayer") {
        const ComponentClass = Vue.extend(
          (await import("./slide-player")).default
        );
        this.slideInstance = new ComponentClass({
          propsData: { slides: element.slides },
        });
        this.slideInstance.$mount();
        this.$refs.slideContainer.appendChild(this.slideInstance.$el);
      }
    });
  },
  data() {
    return {
      slideVisible: false,
      slideInstance: null,
    };
  },
  methods: {
    toggleSlide() {
      this.slideVisible = !this.slideVisible;
    },
    runMethods(time) {
      overlayContent.forEach(async (element) => {
        if (
          element.instance != null &&
          element.instance._data != null &&
          element.in != null && element.stopShowing!=true
        ) {
          if (element.in < time && time < element.out) {
            if (element.instance._data.isActive == false) {
              this.setChildActive(element);
            }
          } else if (element.instance._data.isActive == true) {
            this.setChildInactive(element);
          }
        }
      });
    },
    setChildInactiveEventFromChild(e) {
      var element = overlayContent.find((x) => x.instance === e);
      this.setChildInactive(element,true);
    },
    setChildInactive(element,throughChild){
      if (element.pauseOnVisible) this.$parent.play();
      if(element.isRunOnce&& throughChild)
        element.stopShowing = true;
      if(element.seektoPos!=null)
        this.$parent.seekTo(element.seektoPos);
      element.instance.setInActive();
      if(element.hideControlsOnVisible) this.$parent.allowControlInteraction();
      
    },
    setChildActive(element) {
      element.instance.setActive();
      if (element.pauseOnVisible) this.$parent.pause();
      if(element.hideControlsOnVisible) this.$parent.stopControlInteraction();
    },
    checkforChanges(time,newtime){
      console.log(time,newtime);
      var content=overlayContent.filter(x=>x.out<newtime&&x.notSkipable==true&&x.stopShowing!=true);
      if(content.length==0)
        return null;
      var ascSorted=content.sort(function (a, b) {
          return a.in - b.in
      });
      if(ascSorted[0].type=="quizQuestion");
      alert("Please answer the questions first.")
      return ascSorted[0].in

    }
  },
};
</script>

<style>
</style>